import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import queryClient from '@utils/queries/QueryClient';
import axios from 'axios';

import { useUserDamageParameters } from '@utils/queries/user.queries';
import { Button, Icon, Input } from '@nazka/nazka.mapframe.components'
import { DataGrid } from '@mui/x-data-grid';
import { toast } from 'react-toastify';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { columns, defaultRows } from './damage-parameter-table.constants';
import { setShowDamagePrameterTable } from '../damage-parameter.redux.actions';

import './damage-parameter-table.styl'

function DamageParameterTable({ specificDamageParameterId, close }) {
  const dispatch = useDispatch()
  const { data: userDamageParameters } = useUserDamageParameters()
  const currentDamageParameter = userDamageParameters.find(damage => damage.id === specificDamageParameterId)
  const [rows, setRows] = useState(currentDamageParameter?.landCoverClasses || defaultRows);
  const [parameterName, setParameterName] = useState(currentDamageParameter?.parameterName || null)

  const handleProcessRowUpdate = (newRow, oldRow) => {
    const changedAttributes = Object.keys(newRow).filter(key => newRow[key] !== oldRow[key]);
  
    if (newRow[changedAttributes[0]] < 0) {
      toast.error('Value cannot be negative number')
      return oldRow
    }
    const updatedRows = rows.map(row => (row.id === newRow.id ? newRow : row));
    
    setRows(updatedRows)
    
    return newRow; 
  };

  const { mutate } = useMutation({
    mutationFn: async () => {
      const payload = {
        parameterName: parameterName ?? 'New parameter set',
        landCoverClasses: rows,
      };
      const { data } = await axios.post('/api/damage-parameters', payload);
      return data;
    },
    onSuccess: () => {
      toast.success('Successfully created your custom damage parameter')
      dispatch(setShowDamagePrameterTable(false))
      close()
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['user', 'damage-parameters'] })
    },
  })

  const { mutate: updateUserDamageParameter } = useMutation({
    mutationFn: async (damageParameterId) => {
      await axios.put(`/api/damage-parameters/${damageParameterId}`, {
        parameterName,
        landCoverClasses: rows,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['user', 'damage-parameters'] })
    },
    onSuccess: () => {
      toast.success('Successfully updated your damage parameter')
      dispatch(setShowDamagePrameterTable(false))
      close()
    },
    onError: () => toast.error('Something went wrong updating your area name'),
  });

  return (
    <div className="damage-parameter-table">
      <div className="damage-parameter-table__wrapper">
        <h3>Damage parameters</h3>
        <span>
          Parameter name:
        </span>
        <div>
          <Input
            onInputChange={(e) => {
              setParameterName(e.target.value)
            }} 
            rootElProps={{ placeholder: 'New parameter set' }}
            css={{
              border: '1px solid #ddd',
              borderRadius: '4px',
              outline: 'none',
              height: '25px',
              width: '200px',
            }}
            value={parameterName}
          />
        </div>
       
        <div className="damage-parameter-table__data-grid">
          <DataGrid
            hideFooterPagination
            columns={columns}
            rows={currentDamageParameter?.landCoverClasses || defaultRows}
            rowHeight={25}
            sx={{
              '& .MuiDataGrid-cell': {
                borderRight: '1px solid #e0e0e0',
              },
              '& .MuiDataGrid-root': {
                width: '100%',
              },
              '& .MuiDataGrid-columnHeader': {
                borderRight: '1px solid #e0e0e0',
                backgroundColor: '#f5f5f5',
              },
            }}
            processRowUpdate={handleProcessRowUpdate}
            experimentalFeatures={{ newEditingApi: true }}
            disableColumnVirtualization
            disableVirtualization
          />
        </div>
        <div className="damage-parameter-table__cta__wrapper">
          <div className="damage-parameter-table__info">
            <Icon icon={faInfoCircle} size="24px" />
            <span>All values are in EUR/sqm</span>
            <span>* K(H) and O(H) is a factor that takes flood height into consideration when pereforming damage calculations</span>
          </div>
          <div className="damage-parameter__confirmation__wrapper">
            <Button
              color="#0367A5"
              hoverColor="#0367A5"
              layout="outlined"
              css={{
                borderRadius: '3px',
              }}
              onClick={() => {
                dispatch(setShowDamagePrameterTable(false))
                close()
              }}
            >
              Cancel
            </Button>
            <Button
              color="#0367A5"
              hoverColor="#0367A5"
              css={{
                borderRadius: '3px',
              }}
              onClick={() => {
                if (specificDamageParameterId) {
                  updateUserDamageParameter(specificDamageParameterId)
                } else {
                  mutate()
                }
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DamageParameterTable