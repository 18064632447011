const defaultState = {
  showDamagePrameterTable: false,
  isDamageParameterExpanded: false,
}

const damageParameter = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_SHOW_DAMAGE_PARAMETER_TABLE':
      return {
        ...state,
        showDamagePrameterTable: action.payload,
      }
    case 'SET_DAMAGE_PARAMETER_EXPANDED_STATUS':
      return {
        ...state,
        isDamageParameterExpanded: action.payload,
      }
    default: 
      return state
  }
}

export default damageParameter;