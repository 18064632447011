/* eslint-disable max-lines */
const columns = [
  {
    field: 'classification',
    headerName: 'Urban atlas land cover and damage classes',
    minwidth: 350,
    flex: 0.6,
    editable: false,
    sortable: false, 
    disableColumnMenu: true,
  },
  {
    field: 'buildingConstruction',
    headerName: '* K(H) Building construction',
    type: 'number',
    align: 'right',
    headerAlign: 'center',
    editable: true,
    sortable: false, 
    disableColumnMenu: true,
    flex: 0.2,
  },
  {
    field: 'equipment',
    headerName: '* O(H) Equipment, lost profit',
    type: 'number',
    align: 'right',
    headerAlign: 'center',
    editable: true,
    sortable: false, 
    disableColumnMenu: true,
    flex: 0.2,
  },
  {
    field: 'vehicles',
    headerName: 'Vehicles',
    type: 'number',
    editable: true,
    align: 'right',
    headerAlign: 'center',
    sortable: false, 
    disableColumnMenu: true,
    flex: 0.2,
  },
  {
    field: 'humanHealth',
    headerName: 'Human health',
    type: 'number',
    editable: true,
    align: 'right',
    headerAlign: 'center',
    sortable: false, 
    disableColumnMenu: true,
    flex: 0.2,
  },
  {
    field: 'cleaning',
    headerName: 'Cleaning costs',
    type: 'number',
    editable: true,
    align: 'right',
    headerAlign: 'center',
    sortable: false, 
    disableColumnMenu: true,
    flex: 0.2,
  },
  {
    field: 'farmlandAndForest',
    headerName: 'Farmland and forest',
    type: 'number',
    editable: true,
    align: 'right',
    headerAlign: 'center',
    sortable: false, 
    disableColumnMenu: true,
    flex: 0.2,
  },
  {
    field: 'environment',
    headerName: 'Environment',
    type: 'number',
    editable: true,
    align: 'right',
    headerAlign: 'center',
    sortable: false, 
    disableColumnMenu: true,
    flex: 0.2,
  },
  {
    field: 'infrastructure',
    headerName: 'Infrastructure',
    type: 'number',
    editable: true,
    align: 'right',
    headerAlign: 'center',
    sortable: false, 
    disableColumnMenu: true,
    flex: 0.2,
  },
  {
    field: 'damageTo',
    headerName: 'Damage to water bodies',
    type: 'number',
    editable: true,
    align: 'right',
    headerAlign: 'center',
    sortable: false, 
    disableColumnMenu: true,
    flex: 0.2,
  },
];

const defaultRows = [
  {
    id: '11100',
    classification: 'Continuous urban fabric (S.L. > 80%)',
    buildingConstruction: 380,
    equipment: 426,
    vehicles: 21,
    humanHealth: 8,
    cleaning: 5.04,
    farmlandAndForest: 0,
    environment: 0,
    infrastructure: 1,
    damageTo: 0,
  },
  {
    id: '11210',
    classification: 'Discontinuous dense urban fabric (S.L. 50% - 80%)',
    buildingConstruction: 216,
    equipment: 249,
    vehicles: 8,
    humanHealth: 3,
    cleaning: 5.04,
    farmlandAndForest: 0,
    environment: 0,
    infrastructure: 1,
    damageTo: 0,
  },
  {
    id: '11220',
    classification: 'Discontinuous medium density urban fabric (S.L. 30% - 50%)',
    buildingConstruction: 150,
    equipment: 177,
    vehicles: 6,
    humanHealth: 2,
    cleaning: 5.04,
    farmlandAndForest: 0,
    environment: 0,
    infrastructure: 1,
    damageTo: 0,
  },
  {
    id: '11230',
    classification: 'Discontinuous low density urban fabric (S.L. 10% - 30%)',
    buildingConstruction: 103,
    equipment: 121,
    vehicles: 4,
    humanHealth: 2,
    cleaning: 5.04,
    farmlandAndForest: 0,
    environment: 0,
    infrastructure: 1,
    damageTo: 0,
  },
  {
    id: '11240',
    classification: 'Discontinuous very low density urban fabric (S.L. < 10%)',
    buildingConstruction: 75,
    equipment: 80,
    vehicles: 2,
    humanHealth: 1,
    cleaning: 5.04,
    farmlandAndForest: 0,
    environment: 0,
    infrastructure: 1,
    damageTo: 0,
  },
  {
    id: '11300',
    classification: 'Isolated structures',
    buildingConstruction: 94,
    equipment: 121,
    vehicles: 5,
    humanHealth: 2,
    cleaning: 5.04,
    farmlandAndForest: 0,
    environment: 0,
    infrastructure: 1,
    damageTo: 0,
  },
  {
    id: '12100',
    classification: 'Industrial, commercial, public, military and private units',
    buildingConstruction: 141,
    equipment: 241,
    vehicles: 12,
    humanHealth: 5,
    cleaning: 5.04,
    farmlandAndForest: 0,
    environment: 0,
    infrastructure: 1,
    damageTo: 0,
  },
  {
    id: '12210',
    classification: 'Fast transit roads and associated land',
    buildingConstruction: 0,
    equipment: 0,
    vehicles: 0,
    humanHealth: 0,
    cleaning: 1.26,
    farmlandAndForest: 0,
    environment: 0,
    infrastructure: 7.4,
    damageTo: 0,
  },
  {
    id: '12220',
    classification: 'Other roads and associated land',
    buildingConstruction: 0,
    equipment: 0,
    vehicles: 0,
    humanHealth: 0,
    cleaning: 1.26,
    farmlandAndForest: 0,
    environment: 0,
    infrastructure: 5.2,
    damageTo: 0,
  },
  {
    id: '12230',
    classification: 'Railways and associated land',
    buildingConstruction: 0,
    equipment: 0,
    vehicles: 0,
    humanHealth: 0,
    cleaning: 1.26,
    farmlandAndForest: 0,
    environment: 0,
    infrastructure: 7.4,
    damageTo: 0,
  },
  {
    id: '12300',
    classification: 'Port areas',
    buildingConstruction: 0,
    equipment: 0,
    vehicles: 0,
    humanHealth: 0,
    cleaning: 1.26,
    farmlandAndForest: 0,
    environment: 0,
    infrastructure: 7.4,
    damageTo: 0,
  },
  {
    id: '12400',
    classification: 'Airports',
    buildingConstruction: 0,
    equipment: 0,
    vehicles: 0,
    humanHealth: 0,
    cleaning: 1.26,
    farmlandAndForest: 0,
    environment: 0,
    infrastructure: 7.4,
    damageTo: 0,
  },
  {
    id: '13100',
    classification: 'Mineral extraction and dump sites',
    buildingConstruction: 0,
    equipment: 0,
    vehicles: 0,
    humanHealth: 0,
    cleaning: 0,
    farmlandAndForest: 0,
    environment: 0,
    infrastructure: 1,
    damageTo: 0,
  },
  {
    id: '13300',
    classification: 'Construction sites',
    buildingConstruction: 75,
    equipment: 105,
    vehicles: 3,
    humanHealth: 1,
    cleaning: 1.26,
    farmlandAndForest: 0,
    environment: 0,
    infrastructure: 1,
    damageTo: 0,
  },
  {
    id: '13400',
    classification: 'Land without current use',
    buildingConstruction: 0,
    equipment: 0,
    vehicles: 0,
    humanHealth: 0,
    cleaning: 1.26,
    farmlandAndForest: 0,
    environment: 0,
    infrastructure: 1,
    damageTo: 0,
  },
  {
    id: '14100',
    classification: 'Green urban areas',
    buildingConstruction: 0,
    equipment: 0,
    vehicles: 0,
    humanHealth: 0,
    cleaning: 2.52,
    farmlandAndForest: 0,
    environment: 0.12,
    infrastructure: 1,
    damageTo: 0,
  },
  {
    id: '14200',
    classification: 'Sports and leisure facilities',
    buildingConstruction: 0,
    equipment: 0,
    vehicles: 0,
    humanHealth: 0,
    cleaning: 2.52,
    farmlandAndForest: 0,
    environment: 0,
    infrastructure: 1,
    damageTo: 0,
  },
  {
    id: '21000',
    classification: 'Arable land (annual crops)',
    buildingConstruction: 0,
    equipment: 0,
    vehicles: 0,
    humanHealth: 0,
    cleaning: 0,
    farmlandAndForest: 0.15,
    environment: 0.12,
    infrastructure: 0,
    damageTo: 0,
  },
  {
    id: '22000',
    classification: 'Permanent corps',
    buildingConstruction: 0,
    equipment: 0,
    vehicles: 0,
    humanHealth: 0,
    cleaning: 0,
    farmlandAndForest: 0.15,
    environment: 0.12,
    infrastructure: 0,
    damageTo: 0,
  },
  {
    id: '23000',
    classification: 'Pastures',
    buildingConstruction: 0,
    equipment: 0,
    vehicles: 0,
    humanHealth: 0,
    cleaning: 0,
    farmlandAndForest: 0.05,
    environment: 0.12,
    infrastructure: 0,
    damageTo: 0,
  },
  {
    id: '24000',
    classification: 'Complex and mixed cultivation patterns',
    buildingConstruction: 0,
    equipment: 0,
    vehicles: 0,
    humanHealth: 0,
    cleaning: 0,
    farmlandAndForest: 0.15,
    environment: 0.12,
    infrastructure: 0,
    damageTo: 0,
  },
  {
    id: '31000',
    classification: 'Forests',
    buildingConstruction: 0,
    equipment: 0,
    vehicles: 0,
    humanHealth: 0,
    cleaning: 0,
    farmlandAndForest: 0.02,
    environment: 0.12,
    infrastructure: 0,
    damageTo: 0,
  },
  {
    id: '32000',
    classification: 'Herbaceous vegetation associations',
    buildingConstruction: 0,
    equipment: 0,
    vehicles: 0,
    humanHealth: 0,
    cleaning: 0,
    farmlandAndForest: 0.05,
    environment: 0.12,
    infrastructure: 0,
    damageTo: 0,
  },
  {
    id: '33000',
    classification: 'Open spaces with little or no vegetation',
    buildingConstruction: 0,
    equipment: 0,
    vehicles: 0,
    humanHealth: 0,
    cleaning: 0,
    farmlandAndForest: 0,
    environment: 0.12,
    infrastructure: 0,
    damageTo: 0,
  },
  {
    id: '40000',
    classification: 'Wetlands',
    buildingConstruction: 0,
    equipment: 0,
    vehicles: 0,
    humanHealth: 0,
    cleaning: 0,
    farmlandAndForest: 0,
    environment: 0.12,
    infrastructure: 0,
    damageTo: 0,
  },
  {
    id: '50000',
    classification: 'Water',
    buildingConstruction: 0,
    equipment: 0,
    vehicles: 0,
    humanHealth: 0,
    cleaning: 0,
    farmlandAndForest: 0,
    environment: 0,
    infrastructure: 0,
    damageTo: 6,
  },
];

export {
  columns,
  defaultRows,
}