import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Select, { components } from 'react-select';
import { useUserDamageParameters } from '@utils/queries/user.queries';
import { Button } from '@nazka/nazka.mapframe.components';
import DamageParameterTable from '../../DamageParameter/DamageParameterTable/DamageParameterTable';
import { setShowDamagePrameterTable } from '../../DamageParameter/damage-parameter.redux.actions';

import './custom-select.styl'

function CustomMenu(props) {
  const dispatch = useDispatch()
  return (
    <components.Menu {...props}>
      <>
        {props.children}
        <div>
          <Button
            type="button"
            layout="ghost"
            hover={false}
            css={{
              fontStyle: 'italic',
            }}
            onClick={() => dispatch(setShowDamagePrameterTable(true))}
          >
            Create new parameter set
          </Button>
        </div>
      </>
    </components.Menu>
  );
}

function CustomSelect({ onChange }) {
  const { data: userDamageParameters } = useUserDamageParameters()

  // const selectCustomStyles = {
  //   menuList: provided => ({
  //     ...provided,
  //     maxHeight: '85px',
  //     overflowY: 'auto', 
  //   }),
  // };
  
  const damageParameterOptions = [
    { value: null, label: 'Standard parameter' },
    ...userDamageParameters.map(param => ({
      value: param.id,
      label: param.parameterName, 
    })),
  ]

  return (
    <div>
      <Select
        options={damageParameterOptions}
        components={{ Menu: CustomMenu }}
        defaultValue={damageParameterOptions[0]}
        className="custom-select"
        // styles={selectCustomStyles}
        onChange={selectedOption => onChange(selectedOption.value)}
      />
    </div>
  )
}

export default CustomSelect