import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@nazka/nazka.mapframe.components';
import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons';

import { setActiveShape } from '@library/MaplibreMap/maplibre-map.redux.actions';
import { setIsCreatingNewDamageLayer } from '@App/components/NewDamageLayer/new-damage-layer.redux.actions';
import { setDamageParameterExpandedStatus } from '@App/components/DamageParameter/damage-parameter.redux.actions';

import { removeAllFeatures } from '../Measurement/measurement.utils';

import './measurement-select-type.styl';

function MeasurementSelectType(props) {
  const { map, options } = props;
  const activeShape = useSelector(state => state.maplibreMap.shape);
  const activePopup = useSelector(state => state.maplibreMap.popup);
  const isCreatingNewDamageLayer = useSelector(state => state.newDamageLayer.isCreatingNewDamageLayer);
  const isCreatingNewArea = useSelector(state => state.newDamageLayer.isCreatingNewArea);

  const dispatch = useDispatch();

  if (!isCreatingNewDamageLayer) {
    return (
      <div className="measurement-select-type">
        <Button
          className="measurement-select-type__create"
          icon={faCirclePlus}
          onClick={() => {
            dispatch(setDamageParameterExpandedStatus(false));
            dispatch(setIsCreatingNewDamageLayer(true));
          }}
          iconSize="24px"
        >
          Create damage layer
        </Button>
      </div>
    );
  }

  if (!isCreatingNewArea) {
    return null;
  }

  return (
    <div className="measurement-select-type z-depth-1">
      {options.shapes.map(shape => (
        <button
          type="button"
          title={`Draw ${shape}`}
          className={
            `measurement-select-type__button measurement-select-type__button--${shape}`
            + `${activeShape && activeShape === shape ? ' active' : ''}`
          }
          key={shape}
          onClick={() => {
            if (activeShape !== shape) {
              activePopup?.remove();
              removeAllFeatures(map);
              dispatch(setActiveShape(shape));
              map.fire('measurement-start', { shape });
            } else {
              dispatch(setActiveShape(null));
              map.fire('measurement-end');
            }
          }}
        />
      ))}
    </div>
  );
}

export default MeasurementSelectType;