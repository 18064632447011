/* eslint-disable max-lines, max-len */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Input,
  Radiogroup,
  Toggle,
} from '@nazka/nazka.mapframe.components';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import Select from 'react-select';
import axios from 'axios';

import UrbanAtlasGeojsonLayer from '@App/components/UrbanAtlasGeojsonLayer/UrbanAtlasGeojsonLayer';
import UrbanAtlasWMSLayer from '@App/components/UrbanAtlasWMSLayer/UrbanAtlasWMSLayer';
import { mainOptions, secondaryOptions } from '@App/components/FloodScenarioModal/flood-scenario-modal.constants';
import { setCurrentSelectedDamageLayer } from '@App/components/LayerSelector/layer-selector.redux.action';
import {
  useUpdateUserSettingsMutation, useUserAreas, useUserSettings, 
  useSpecificUserDamageParameter, 
} from '@utils/queries/user.queries';
import { fitToUserArea } from '@library/MaplibreMap/maplibre-map.utils';
import { setActiveShape, setShowActiveDrawingLayer } from '@library/MaplibreMap/maplibre-map.redux.actions';
import { removeAllFeatures } from '@library/MaplibreMap/controls/Controls/CustomControls/MeasurementControl/Measurement/measurement.utils';
import queryClient from '@utils/queries/QueryClient';
import { URBAN_ATLAS_COLOR_MAP } from '@App/components/UrbanAtlasGeojsonLayer/urban-atlas-geojson-layer.constants';
import BuildingsLayer from '@App/components/BuildingsLayer/BuildingsLayer';
import { useUrbanAtlasLandUseFeatures } from '@utils/queries/map.queries';

import { setIsCreatingNewArea, setIsCreatingNewDamageLayer, setSelectedAreaId } from './new-damage-layer.redux.actions';
import { isValidFloodHeight } from './new-damage-layer.utils';
import UACClassesSelector from './UACClassesSelector/UACClassesSelector';

import './new-damage-layer.styl';
import CustomSelect from './CustomSelect/CustomSelect';

const BUILDING_OPTIONS = [{
  label: 'My buildings',
  value: 'my-buildings',
}];

function NewDamageLayer() {
  const [name, setName] = useState('');
  const [selectedLandUseFeatures, setSelectedLandUseFeatures] = useState();
  const [selectedUACClasses, setSelectedUACClasses] = useState(URBAN_ATLAS_COLOR_MAP.filter(({ disabled }) => !disabled).map(({ value }) => value));
  const [floodHeight, setFloodHeight] = useState(0.62);
  const [floodHeightError, setFloodHeightError] = useState('');
  const [selectedBuildingLayer, setSelectedBuildingLayer] = useState(BUILDING_OPTIONS[0].value);
  const [selectedDamageParameterId, setSelectedDamageParameterId] = useState(null)

  const selectedAreaId = useSelector(state => state.newDamageLayer.selectedAreaId);
  const isCreatingNewArea = useSelector(state => state.newDamageLayer.isCreatingNewArea);
  const map = useSelector(state => state.maplibreMap.map);

  const dispatch = useDispatch();

  const { mutate: updateUserSettings } = useUpdateUserSettingsMutation();
  const { data: userSettings } = useUserSettings();
  const { data: userAreas } = useUserAreas();
  const { data: specificUserDamageParameter } = useSpecificUserDamageParameter(selectedDamageParameterId);

  const area = userAreas?.find(a => a.id === selectedAreaId);
  const { data: urbanAtlasLandUseFeatures, isLoading } = useUrbanAtlasLandUseFeatures(area);

  const validSelectedUACClasses = selectedUACClasses.filter(c => urbanAtlasLandUseFeatures?.features?.find(({ properties }) => Number(properties.code_2018) === c));

  const {
    floodMeasures,
    floodYear,
  } = userSettings || {};

  const { mutate, isPending, isError } = useMutation({
    mutationFn: async () => {
      const payload = {
        areaId: selectedAreaId,
        model: selectedLandUseFeatures === 'buildings' ? 'default' : 'uac',
        description: name || 'New damage layer',
        scenario: `${floodYear}${floodMeasures || ''}`,
        floodHeight,
        specificUserDamageParameter: specificUserDamageParameter || null,
      };

      if (selectedLandUseFeatures === 'urbanAtlasCover') {
        payload.selectedUACClasses = validSelectedUACClasses;
      }

      return axios.post('/api/calculation/damage', payload).then(result => result.data)
    },
    onSuccess: (createdDamageLayer) => {
      dispatch(setShowActiveDrawingLayer(true));
      toast.success('Damage layer created');
      const previousDamageLayers = queryClient.getQueryData(['user', 'flood-layers']) || [];
      queryClient.setQueryData(['user', 'flood-damages'], [createdDamageLayer, ...previousDamageLayers]);
      dispatch(setCurrentSelectedDamageLayer(createdDamageLayer));
      dispatch(setIsCreatingNewDamageLayer(false));
    },
  });

  useEffect(() => {
    return () => {
      if (map) {
        dispatch(setSelectedAreaId(null));
        map.fire('measurement-end');
        removeAllFeatures(map);
      }
    }
  }, [dispatch, map]);

  const userAreaOptions = userAreas?.map(userArea => ({ value: userArea.id, label: userArea.areaName }));
  const userAreaValue = userAreaOptions?.find(o => o.value === selectedAreaId) || null;

  return (
    <div className="new-damage-layer">
      <h2 className="new-damage-layer__title">
        New damage layer
      </h2>
      <div className="new-damage-layer__content">
        <label htmlFor="damage-layer-name" className="new-damage-layer__content__name__label">
          Damage layer name:
        </label>
        <Input
          className="new-damage-layer__content__name__input"
          value={name}
          onInputChange={e => setName(e.target.value)}
          rootElProps={{
            id: 'damage-layer-name',
            placeholder: 'Damage layer name...',
          }}
          disabled={isCreatingNewArea}
        />
        <p>
          Select your flood scenario:
        </p>
        <div className="new-damage-layer__content__scenario">
          <Select
            className="new-damage-layer__content__scenario__main"
            options={mainOptions}
            value={mainOptions.find(o => o.value === floodYear) || mainOptions[0]}
            onChange={(selectedOption) => {
              updateUserSettings({ floodYear: selectedOption?.value });
            }}
            isDisabled={isCreatingNewArea}
          />
          <Select
            className="new-damage-layer__content__scenario__secondary"
            options={secondaryOptions}
            value={secondaryOptions.find(o => o.value === floodMeasures) || secondaryOptions[0]}
            onChange={(selectedOption) => {
              updateUserSettings({ floodMeasures: selectedOption?.value });
            }}
            isDisabled={isCreatingNewArea}
          />
        </div>
        {selectedLandUseFeatures === 'urbanAtlasCover' && (
          <div>
            <div className="new-damage-layer__content__height">
              <label htmlFor="flood-height">
                Flood height (in m):
              </label>
              <Input
                rootElProps={{
                  id: 'flood-height',
                  min: 0.1,
                  max: 10,
                  step: 0.1,
                }}
                css={{
                  borderColor: floodHeightError ? 'red' : undefined,
                }}
                disabled={isCreatingNewArea}
                value={floodHeight}
                type="number"
                onInputChange={(e) => {
                  setFloodHeightError(!isValidFloodHeight(e.target.value));
                  setFloodHeight(e.target.value);
                }}
              />
            </div>
            {floodHeightError && (
              <span style={{ color: 'red', display: 'block' }}>
                Please specify a height between 0.1 and 10 meters
              </span>
            )}
          </div>
        )}
        <p>
          Select your area of interest:
        </p>
        <div className="new-damage-layer__content__area">
          <Button
            className="new-damage-layer__content__area__button"
            onClick={() => {
              const newIsCreatingNewArea = !isCreatingNewArea;
              removeAllFeatures(map);

              if (newIsCreatingNewArea) {
                dispatch(setActiveShape('circle'));
                dispatch(setSelectedAreaId(null));
                map.fire('measurement-start', { shape: 'circle' });
              } else {
                map.fire('measurement-end');
              }

              dispatch(setIsCreatingNewArea(newIsCreatingNewArea));
            }}
            icon={isCreatingNewArea ? null : faPencil}
            css={{ flexDirection: 'row-reverse' }}
          >
            {isCreatingNewArea ? 'Cancel drawing' : 'Draw new area'}
          </Button>
          {!isCreatingNewArea && userAreas?.length > 0 && (
            <Select
              className="new-damage-layer__content__area__select"
              options={userAreaOptions}
              onChange={(selectedOption) => {
                const userArea = userAreas.find(uA => uA.id === selectedOption.value);
                map.getSource('measurement-control').setData({ type: 'FeatureCollection', features: [userArea.feature] });
                fitToUserArea(map, userArea);
                dispatch(setSelectedAreaId(selectedOption.value));
              }}
              value={userAreaValue}
              isDisabled={isCreatingNewArea}
            />
          )}
        </div>
        <p>
          Select your land use features:
        </p>
        <div className="new-damage-layer__content__land-use-features">
          <div className="new-damage-layer__content__land-use-features__urban-atlas-cover">
            <Toggle
              checked={selectedLandUseFeatures === 'urbanAtlasCover'}
              label="Urban Atlas Cover"
              onInputChange={(e) => {
                setSelectedLandUseFeatures(e.target.checked ? 'urbanAtlasCover' : null);
              }}
              disabled={isCreatingNewArea}
            />
            {selectedLandUseFeatures === 'urbanAtlasCover' && (
              <UACClassesSelector
                selectedUACClasses={selectedUACClasses}
                onSelectedUACClassesChange={setSelectedUACClasses}
                disabled={isCreatingNewArea || !selectedAreaId}
                availableUACClasses={urbanAtlasLandUseFeatures?.features?.map(({ properties }) => Number(properties.code_2018))}
                isLoading={isLoading}
              />
            )}
          </div>
          <div className="new-damage-layer__content__land-use-features__buildings">
            <Toggle
              checked={selectedLandUseFeatures === 'buildings'}
              label="Buildings"
              onInputChange={(e) => {
                setSelectedLandUseFeatures(e.target.checked ? 'buildings' : null);
              }}
              disabled={isCreatingNewArea}
            />
            {selectedLandUseFeatures === 'buildings' && (
              <Radiogroup
                radios={BUILDING_OPTIONS}
                selectedValue={selectedBuildingLayer}
                onInputChange={e => setSelectedBuildingLayer(e.target.value)}
                css={{ marginLeft: '52px', marginTop: '6px' }}
              />
            )}
          </div>
        </div>
        {selectedLandUseFeatures === 'urbanAtlasCover' && (
          <div>
            <p>
              Select your damage parameters:
            </p>
            <CustomSelect onChange={setSelectedDamageParameterId} />
          </div>
        )}

      </div>
      {isError && (
        <span className="new-damage-layer__error">
          Something went wrong creating the damage layer. If this error persists, please contact support.
        </span>
      )}
      <div className="new-damage-layer__actions">
        <Button
          disabled={isCreatingNewArea || !selectedLandUseFeatures || (selectedLandUseFeatures === 'urbanAtlasCover' && (isLoading || validSelectedUACClasses.length <= 0)) || !selectedAreaId || !name || floodHeightError}
          onClick={mutate}
          isLoading={isPending}
        >
          Calculate
        </Button>
        <Button
          layout="outlined"
          onClick={() => {
            dispatch(setIsCreatingNewDamageLayer(false));
            dispatch(setIsCreatingNewArea(false));
          }}
        >
          Cancel
        </Button>
      </div>
      {map && selectedLandUseFeatures === 'buildings' && (
        <BuildingsLayer map={map} />
      )}
      {map && selectedLandUseFeatures === 'urbanAtlasCover' && selectedAreaId && (
        <UrbanAtlasGeojsonLayer
          map={map}
          selectedClasses={selectedUACClasses}
          features={urbanAtlasLandUseFeatures}
        />
      )}
      {map && selectedLandUseFeatures === 'urbanAtlasCover' && !selectedAreaId && (
        <UrbanAtlasWMSLayer map={map} />
      )}
    </div>
  );
}

export default NewDamageLayer;